















































import Vue from "vue";
import { Toast } from "vant";
import { dispatch, Address } from "@/store";
import { area } from "@/utils";

export default Vue.extend({
  data() {
    return {
      area,
      loading: true,
      address: {} as Address,
      areaVisible: false,
      addrCode: "",
    };
  },
  computed: {
    id() {
      return this.$route.params.id;
    },
    isAdd() {
      return !!this.$route.params.id;
    },
    redirect() {
      return this.$route.query.redirect as string;
    },
  },
  methods: {
    onChange(key: string, value: string) {
      (this.address as any)[key] = value;
    },
    defaultAddressChange(boolead: boolean) {
      this.address.is_default = boolead;
    },
    onAreaChange(areas: any) {
      this.address.province = areas[0].name;
      this.address.city = areas[1].name;
      this.address.district = areas[2].name;
      this.areaVisible = false;
    },
    onSave() {
      if (!this.address.consignee) {
        Toast({ message: "收货人姓名不得为空" });
        return false;
      }
      if (this.address.consignee.length > 10) {
        Toast({ message: "收货人姓名不能超过 10 个字符" });
        return false;
      }
      if (!/^1[3456789]\d{9}$/.test(this.address.phone)) {
        Toast({ message: "请输入正确的联系人电话" });
        return false;
      }
      if (
        !this.address.province ||
        !this.address.city ||
        !this.address.district
      ) {
        Toast({ message: "请选择所在地区" });
        return false;
      }
      if (!this.address.detail) {
        Toast({ message: "详细地址不得为空" });
        return false;
      }
      if (this.address.detail.length > 40) {
        Toast({ message: "详细地址不能超过 40 个字符" });
        return false;
      }
      this.address.country = "中国";
      this.address.is_default = !!this.address.is_default;
      if (this.address.id) {
        dispatch.addressesPatch(this.address).then(() => {
          Toast("收货地址已修改");
          this.$router.back();
        });
      } else {
        dispatch.addressesPost(this.address).then(() => {
          Toast("收货地址已添加");
          if (this.redirect) {
            this.$router.replace(this.redirect);
          } else {
            this.$router.back();
          }
        });
      }
    },
  },
  mounted() {
    if (this.id) {
      dispatch.addressesGet(this.id).then((res) => {
        this.loading = false;
        this.address = res;
      });
    } else {
      this.loading = false;
    }
  },
});
